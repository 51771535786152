<template>

</template>

<script>
  export default {
    name: "resetSuccess",
    mounted() {
      this.$router.push('/login')
      this.open();
    },
    methods: {
      open() {
        this.$alert(window.vm.$t('message.password.xiugai'), window.vm.$t('message.password.reset'), {
          confirmButtonText:window.vm.$t('message.OK'),
        });
      },

    }
  };
</script>

<style scoped>

</style>